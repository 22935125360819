<template>
  <div class="mt-5">
    <!-- Prêmios retidos  -->
    <v-expansion-panels v-model="isExpanded">
      <v-expansion-panel @click="carregarTotais">
        <v-expansion-panel-header>
          Clique aqui para ver dados estatísticos
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container
            class="text-center"
            v-if="loading"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </v-container>
          <v-container 
            class="pl-0 pr-0 ml-0 mr-0"
            fluid 
            v-else
          >
            
            <v-data-iterator
              :items="listStatistics.gameStatistics"
              :search="search"
              :sort-by="sortBy.toLowerCase()"
              :sort-desc="sortDesc"
              hide-default-footer
              :loading="loading"
              :items-per-page="listStatistics.gameStatistics.length"
            >
              <template v-slot:header>
                <v-toolbar
                  dark
                  :color="variables.colorPrimaryDarken"
                  class="mb-1 header-dados-estatisticos"
                  :height="45"
                >
                  <h3>Dados Estatísticos</h3>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    clearable
                    flat
                    solo-inverted
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Filtrar loteria"
                    class="flex-grow-0"
                  ></v-text-field>
                  <template v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- <v-spacer></v-spacer>
                    <v-select
                      v-model="sortBy"
                      flat
                      solo-inverted
                      hide-details
                      :items="keys"
                      prepend-inner-icon="mdi-magnify"
                      label="Sort by"
                    ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn-toggle
                      v-model="sortDesc"
                      mandatory
                    >
                      <v-btn
                        large
                        depressed
                        color="blue"
                        :value="false"
                      >
                        <v-icon>mdi-arrow-up</v-icon>
                      </v-btn>
                      <v-btn
                        large
                        depressed
                        color="blue"
                        :value="true"
                      >
                        <v-icon>mdi-arrow-down</v-icon>
                      </v-btn>
                    </v-btn-toggle> -->
                  </template>
                </v-toolbar>
              </template>

              <template v-slot:default="props">
                <v-row>
                  <v-col
                    cols="12"

                    v-if="!search.length"
                  >
                    <v-card class="card-estatistica">
                      <v-card-title class="subheading font-weight-bold">
                        Geral
                      </v-card-title>

                      <v-divider></v-divider>

                      <v-list dense>
                        <v-row>
                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Total de Rev. Ativos Hoje:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['ag_ativas'] | numeral }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Total de Rev. que Venderam:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['ag_com_propostas'] | numeral }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Total de Rev. que Faltaram</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['ag_faltaram'] | numeral }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Total de Bilhetes do Período:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['total_bilhetes'] | numeral }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Média de Vendas dos Rev.::</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['media_vendas']| currencyNoSymbol }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>

                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Proposta Líquida:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['total_vendas'] | currencyNoSymbol }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Vendas por Loteria:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['total_vendas'] | currencyNoSymbol }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          
                          <v-col cols="12" md="3" class="pb-0 pt-0">
                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>Prêmios por Loteria:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['total_premios'] | currencyNoSymbol }}
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                              <v-list-item-content style="flex-grow: 1;">
                                <strong>% de premiação:</strong>
                              </v-list-item-content>
                              <v-list-item-content
                                class="align-end"
                                style="flex-grow: 1;"
                              >
                                {{ listStatistics.entityStatistics['porcentagem_premios'] | percentage }}
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </v-list>
                    </v-card>

                  </v-col>
                  <v-col
                    v-for="item in props.items"
                    :key="item.cod"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                  >
                    <v-card class="card-estatistica">
                      <v-card-title class="subheading font-weight-bold">
                        {{ item.cod }}
                      </v-card-title>

                      <v-divider></v-divider>

                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content style="flex-grow: 3;">
                            <strong>Total de Bilhetes do Período:</strong>
                          </v-list-item-content>
                          <v-list-item-content
                            class="align-end"
                            style="flex-grow: 1;"
                          >
                            {{ item['total_bilhetes'] | numeral }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content style="flex-grow: 3;">
                            <strong>Vendas por Loteria:</strong>
                          </v-list-item-content>
                          <v-list-item-content
                            class="align-end"
                            style="flex-grow: 1;"
                          >
                            {{ item['total_vendas'] | currencyNoSymbol }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content style="flex-grow: 3;">
                            <strong>Prêmios por Loteria:</strong>
                          </v-list-item-content>
                          <v-list-item-content
                            class="align-end"
                            style="flex-grow: 1;"
                          >
                            {{ item['prize_value'] | currencyNoSymbol }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content style="flex-grow: 3;">
                            <strong>% de premiação:</strong>
                          </v-list-item-content>
                          <v-list-item-content
                            class="align-end"
                            style="flex-grow: 1;"
                          >
                            {{ item['porcentagem_premios'] | percentage }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:loading>
                <div class="text-center mt-5">
                  <v-progress-circular
                    :size="35"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </template>
            </v-data-iterator>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>  
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapActions, mapGetters } from 'vuex'
// import moment from 'moment'

const namespaceStore = 'relatoriosGecom'

export default {
  name: 'DadosEstatistica',
  props: {
    dateInit: {
      type: String,
      default: ''
    },

    dateEnd: {
      type: String,
      default: ''
    },

    count: {
      type: Number,
      default: 0
    }
  },

  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    search: '',
    filter: {},
    sortDesc: false,
    page: 1,
    loading: false,
    itemsPerPage: 4,
    sortBy: 'name',
    isExpanded: false,
    keys: [
      'Name',
      'Calories',
      'Fat',
      'Carbs',
      'Protein',
    ],
  }),

  watch: {
    count () {
      this.isExpanded = false
    }
  },

  computed: {
    variables: () => variables,
    filteredKeys () {
      return this.keys.filter(key => key !== 'Name')
    },
    ...mapGetters(namespaceStore, ['listStatistics']),
  },

  mounted () {
    // this.loading = true
    // const date = moment().format('YYYY-MM-DD')
    // this.getEstatística({ dateInit: date, dateEnd: date }).finally(() => this.loading = false)
  },

  methods: {
    ...mapActions(namespaceStore, ['getEstatística']),
    atualizarEstatistica () {
      this.loading = true
      this.getEstatística({ dateInit: this.dateInit, dateEnd: this.dateEnd }).finally(() => this.loading = false)
    },

    carregarTotais () {
      this.isExpanded = !this.isExpanded

      if (this.isExpanded) {
        this.isLoadingTotais = true
        this.atualizarEstatistica()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-dados-estatisticos::v-deep {
    .v-input__slot {
      margin-bottom: 0 !important;
    }

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 35px;
    }
  }

  .card-estatistica::v-deep {
    .v-card__title {
      font-size: 1rem;
      padding: 10px;
      line-height: 1.2rem;
    }

    .v-list-item {
      padding: 0 10px;
    }

    .v-list-item__content {
      padding: 3px 0;
      font-size: 0.8rem;

      strong {
        font-size: 0.7rem;
      }

      &.align-end {
        width: 100% !important;
        display: block;
        text-align: right;
      }
    }

    .v-list-item--dense, .v-list--dense .v-list-item {
      min-height: 25px;
    }
  }

</style>